import React from 'react';
import { Modal } from 'react-bootstrap';

// eslint-disable-next-line no-unused-vars
import { CardContentModel, HowItWorksModel, HowItWorksStates } from '../models';
import HowItWorksItem from './how-it-works-item';
import Icon from './icon';
import IntroVideo from './intro-video';

class HowItWorks extends React.Component<HowItWorksModel, HowItWorksStates> {
  constructor(props: HowItWorksModel) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  openIntroVideo = () => {
    this.setState({ isModalOpen: true });
  };

  closeIntroVideo = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    return (
      <div className="container">
        <header className="row text-center pt-6 pb-3">
          <div className="col">
            <h2>How it works</h2>
            <p className="lead">{this.props.intro}</p>
          </div>
        </header>

        <div className="row text-center font-weight-bold position-relative">
          <div
            className={`connect-steps ${this.props.theme} d-none d-sm-block`}
          />
          {this.props.howItWorks.map(
            (item: CardContentModel, index: number) => (
              <div className="col-md-4" key={index}>
                <HowItWorksItem icon={item.icon} title={item.title} />
              </div>
            )
          )}
        </div>

        {this.props.hasRequestADemo ? (
          <footer className="d-flex justify-content-center">
            <a
              href="https://theapsgroup.typeform.com/to/u8NWFprV"
              target="_blank"
              aria-label="Open a form to request a demo"
              className="btn btn-primary mt-3"
            >
              <span className="align-middle">request a demo</span>
            </a>
          </footer>
        ) : (
          ''
        )}

        {this.props.hasSeeHowItWorks ? (
          <div className="text-center">
            <button
              aria-label="See how it works, Play video"
              className="btn btn-primary mt-6"
              onClick={this.openIntroVideo}
              type="button"
            >
              <span className="mr-2">
                <Icon>play_circle_outline</Icon>
              </span>
              <span className="align-middle">see how it works</span>
            </button>

            <Modal
              aria-labelledby="video-label"
              size="lg"
              centered
              show={this.state.isModalOpen}
              onHide={this.closeIntroVideo}
              dialogClassName="pmp-intro-video"
            >
              <Modal.Body>
                <IntroVideo video={this.props.video} />
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default HowItWorks;
